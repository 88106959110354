import React, { useState } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Title from "../elements/CardTitle";
import NextButton from "../elements/NextButton";
import Wrapper from "../elements/CardWrapper";
import CommentInput from "../elements/CommentInput";
import ConcertableQuestion from "../../components/Travaux/ConcertableLayouts/ConcertableUtils/concertableQuestion";
import LinearProgress from "@material-ui/core/LinearProgress";
import { uploadAnswer } from "../../utils/TravauxUtils";

const Image = styled.img`
  width: 25rem;
  @media screen and (min-width: 910px) {
    width: 100%;
  }
`;
const ImageWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40rem;
  position: relative;
  /* margin-bottom: -3rem;
  margin-top: -3rem; */
  @media screen and (min-width: 910px) {
    margin-bottom: 0;
    margin-top: 0;
  }
`;

const QuestionWrapper = styled(motion.div)`
  background: #f9f9f9;
  border-radius: 20px;
  box-shadow: inset 0px -7px 0px #bebebe;
  margin: 2rem;
  padding: 1rem 1rem;
`;

const CommentWrapper = styled(motion.div)`
  margin: 1rem 2rem;
`;

const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
`;

const RadioType = ({
  dispatch,
  contentData,
  cardID,
  cardIndex,
  contentIndex,
  contentLength,
  currentUser,
  cardsLength,
}) => {
  const { contentId, question, title, imageUrl, value, options, comment } =
    contentData;

  const handleChange = (event, contentID, cardID, contentIndex, cardIndex) => {
    // Update the answers value for the given question
    dispatch({
      type: "UPDATE_ANSWER",
      cardID,
      contentID,
      contentIndex,
      cardIndex,
      answer: event.target.value,
    });

    dispatch({
      type: "UPDATE_IMAGE",
      cardID,
      contentID,
      contentIndex,
      cardIndex,
    });
  };

  const handleSubmit = (cardID, contentId, value) => {
    let answers = {
      cardID: cardID,
      ["value"]: value,
      ["comment"]: comment,
    };
    uploadAnswer(currentUser.uid, cardID, contentId, answers, dispatch);
  };

  const questionElement = (
    <ConcertableQuestion
      contentId={contentId}
      question={{
        type: "radio",
        title: question,
        options: options,
        value: value,
      }}
      cardID={cardID}
      cardIndex={cardIndex}
      contentIndex={contentIndex}
      handleChange={handleChange}
    />
  );

  return (
    <Wrapper
      variant="cardMain"
      layoutId="wrapper"
      initial={{ borderRadius: 40 }}
      animate={{ borderRadius: 0 }}
      transition={{ duration: 0.5 }}
    >
      <LinearProgress
        variant="determinate"
        style={{ width: "85%", margin: "1rem auto" }}
        color="secondary"
        value={(contentIndex / contentLength) * 100}
      />
      <Title
        layoutId="title"
        transition={{ duration: 0.5 }}
        color="#fff"
        style={{ paddingLeft: "2rem" }}
      >
        {title}
      </Title>

      <ImageWrapper
        layoutId="imageIllustration"
        drag="x"
        dragConstraints={{ left: -50, right: 50 }}
      >
        <Image
          alt="La résidentialisation"
          src={require(`../../assets/travaux/${imageUrl}`)}
        />
      </ImageWrapper>

      <QuestionWrapper>
        {questionElement}

        {value === "rep100" ? (
          <CommentWrapper>
            <CommentInput
              comment={comment}
              dispatch={dispatch}
              cardID={cardID}
              contentIndex={contentIndex}
              cardIndex={cardIndex}
              label="Votre idée"
            />
          </CommentWrapper>
        ) : null}
      </QuestionWrapper>

      <ButtonWrapper>
        <NextButton
          handleSubmit={() => handleSubmit(cardID, contentId, value)}
          href={
            parseInt(contentIndex) < contentLength - 1
              ? `/travaux/${cardID}/${parseInt(contentIndex) + 1}`
              : "/travaux"
          }
        >
          Valider
        </NextButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default RadioType;
