import React, { useState } from "react";
import ReactPlayer from "react-player";

import classes from "./Projet.module.css";
import styled from "styled-components";

// Components
import Articles from "./Articles/Articles";
import UploadDocument from "./UploadDocument/UploadDocument";

const Projet = ({ width, claims, clicked }) => {
  const [pdfs, setPdfs] = useState();
  return (
    <div className={classes.Projet}>
      <div className={classes.DescriptionSection}>
        <div className={classes.DescriptionSvg}>
          <img
            style={{ width: "100%" }}
            alt="hand"
            src={require(`../../../assets/homePage/concertation.svg`)}
          />
        </div>
        <div className={classes.BackgroundShape}></div>
        <div className={classes.Description}>
          <p>
            La Métropole Rouen Normandie s’engage aux côtés de la Ville de
            Petit-Quevilly, des bailleurs, de l’ANRU, de la Région Normandie et
            d’autres partenaires, dans un programme de réaménagement des espaces
            publics sur le quartier de la Piscine pour :
          </p>
          <ul>
            <li>
              Renforcer l’ouverture du quartier et favoriser la mobilité des
              habitants et des usagers
            </li>
            <li>Améliorer le cadre de vie des riverains</li>
          </ul>
          <p>
            La transformation du quartier de la Piscine se poursuit. La Ville de
            Petit-Quevilly et la Métropole Rouen Normandie organisent une
            démarche de concertation pour la création des futurs espaces publics
            aux abords de la Plaine des Sports et de la Piscine, visant à
            informer et à impliquer les habitants et usagers locaux. La démarche
            d’e-concertation consiste à la mise en place d’une plateforme
            citoyenne et au développement d’une application web visant à
            recueillir les attentes des futurs usagers de ces espaces publics.
          </p>
          <p>
            Après avoir participé aux futurs aménagements et équipement de la
            Plaine des Sports…Et si aujourd’hui, on repensait totalement
            l’éclairage ? Qu’on renommait la place autour de la Piscine ? Le
            passage Allende ?
          </p>
          <p>
            Grâce à cet outil numérique, il s’agit d’intégrer les habitants et
            les usagers au cœur de la démarche de concertation et de permettre à
            tous de s’impliquer dans la conception du projet. Les résultats de
            la concertation alimenteront la mission  de l’équipe chargée de la
            conception du projet d’aménagement. Ces futurs aménagements sont
            financés grâce à l’Agence Nationale pour la rénovation urbaine
            (ANRU), la Région Normandie, la ville de Petit-Quevilly et la
            Métropole Rouen Normandie.
          </p>
          <p>
            L’agence 1001 Rues a été choisie pour développer les outils de cette
            e-concertation accompagnée de l’agence TRAITCLAIR en charge des
            actions de concertation (support, balade urbaine, stands, etc).
          </p>

          <div className={classes.ActionButton}>
            <p onClick={clicked}>Commencer la concertation</p>
          </div>
        </div>
      </div>
      <div className={classes.Articles}>
        <div className={classes.ArticlesHeader}>
          <h4 style={{ marginBottom: "1.5rem" }}>Actualités</h4>
          {claims && claims.admin && (
            <UploadDocument setPdfs={setPdfs} claims={claims} />
          )}
        </div>
        <Articles setPdfs={setPdfs} pdfs={pdfs} claims={claims} width={width} />
      </div>
    </div>
  );
};

export default Projet;
