import React, { createContext, useReducer } from "react";
// REDUCERS
import { ProfileSetupReducer } from "../reducers/ProfileSetupReducer";

export const ProfileSetupContext = createContext();

const ProfileSetupContextProvider = ({ children }) => {
  const [profileSetupData, dispatch] = useReducer(ProfileSetupReducer, {
    name: {
      elementType: "Input",
      elementConfig: {
        type: "text",
        // placeholder: "Renseignez votre prénom ici",
        required: true,
      },
      value: "",
    },
    lastName: {
      elementType: "Input",
      elementConfig: {
        type: "text",
        // placeholder: "Renseignez votre nom ici",
        required: true,
      },
      value: "",
    },

    birthYear: {
      elementType: "Input",
      elementConfig: {
        type: "text",
        placeholder: "",
        maxLength: "4",
      },
      value: "",
    },
    flatSize: {
      elementType: "radio",
      elementConfig: {
        name: "flatSize",
        options: [
          {
            value: "F1",
            displayValue: "F1",
            id: "Log1",
            checked: false,
          },
          { value: "F2", displayValue: "F2", id: "Log2", checked: false },
          { value: "F3", displayValue: "F3", id: "Log3", checked: false },
          { value: "F4", displayValue: "F4", id: "Log4", checked: false },
          { value: "F5", displayValue: "F5", id: "Log5", checked: false },
          { value: "F6", displayValue: "F6", id: "Log6", checked: false },
          { value: "plus", displayValue: "Plus", id: "Log7", checked: false },
        ],
      },
      value: "",
    },
    sexe: {
      elementType: "radio",
      elementConfig: {
        name: "sexe",
        options: [
          {
            value: "homme",
            displayValue: "Homme",
            id: "homme",
            checked: false,
          },
          {
            value: "femme",
            displayValue: "Femme",
            id: "femme",
            checked: false,
          },
        ],
      },
      value: "",
    },
    numCars: {
      elementType: "radio",
      elementConfig: {
        name: "numCars",
        options: [
          {
            value: "aucune",
            displayValue: "Aucune",
            id: "Car1",
            checked: false,
          },
          { value: "une", displayValue: "Une", id: "Car2", checked: false },
          { value: "deux", displayValue: "Deux", id: "Car3", checked: false },
          { value: "plus", displayValue: "Plus", id: "Car4", checked: false },
        ],
      },
      value: "",
    },
    numMotors: {
      elementType: "radio",
      elementConfig: {
        name: "numMotors",
        options: [
          { value: "aucun", displayValue: "Aucun", id: "Mot1", checked: false },
          { value: "un", displayValue: "Un", id: "Mot2", checked: false },
          { value: "deux", displayValue: "Deux", id: "Mot3", checked: false },
          { value: "plus", displayValue: "Plus", id: "Mot4", checked: false },
        ],
      },
      value: "",
    },
    numBikes: {
      elementType: "radio",
      elementConfig: {
        name: "numBikes",
        options: [
          {
            value: "aucun",
            displayValue: "Aucun",
            id: "Bike1",
            checked: false,
          },
          { value: "un", displayValue: "Un", id: "Bike2", checked: false },
          { value: "deux", displayValue: "Deux", id: "Bike3", checked: false },
          { value: "plus", displayValue: "Plus", id: "Bike4", checked: false },
        ],
      },
      value: "",
    },
    habitant: {
      elementType: "radio",
      elementConfig: {
        name: "habitant",
        options: [
          {
            value: "oui",
            displayValue: "Oui",
            id: "Hab1",
            checked: false,
          },
          { value: "non", displayValue: "Non", id: "Hab2", checked: false },
          {
            value: "jecomptemyinstaller",
            displayValue: "Je compte m’y installer",
            id: "Hab3",
            checked: false,
          },
        ],
      },
      value: "",
    },
    habitation: {
      elementType: "radio",
      elementConfig: {
        name: "habitation",
        options: [
          {
            value: "pavillon",
            displayValue: "Un pavillon",
            id: "Habi1",
            checked: false,
          },
          {
            value: "appartement",
            displayValue: "Un appartement",
            id: "Habi2",
            checked: false,
          },
        ],
      },
      value: "",
    },
  });

  return (
    <ProfileSetupContext.Provider value={{ profileSetupData, dispatch }}>
      {children}
    </ProfileSetupContext.Provider>
  );
};

export default ProfileSetupContextProvider;
