import React from "react";

export const travauxCardsData = [
  {
    id: "i1",
    type: "newLayout",
    titleStrong: "La requalification",
    titleWeak: "des voiries du quartier de la piscine",
    description:
      "Dans le cadre de l’aménagement des espaces publics du Quartier de la Piscine, les voiries sont repensées pour rendre votre quartier plus agréable.",
    thumbnail: "i1-thumb.png",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Dans le cadre de l’aménagement des espaces publics du Quartier de la Piscine, les voiries sont repensées pour rendre votre quartier plus agréable.",
        title: "La requalification des voiries du quartier de la piscine",
        video: {
          url: "https://youtu.be/5BYJh9DF2wE",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "i2",
    type: "newLayout",
    titleStrong: "Le phasage",
    titleWeak: "des travaux d’aménagement",
    description:
      "Le projet d’aménagement des espaces publics du Quartier de la Piscine va prendre un certain temps. Pour cette raison, les travaux sont découpés en différentes phases successives.",
    thumbnail: "i2-thumb.png",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "videoYoutube",
        description:
          "Le projet d’aménagement des espaces publics du Quartier de la Piscine va prendre un certain temps. Pour cette raison, les travaux sont découpés en différentes phases successives.",
        title: "Le phasage des travaux d’aménagement",
        video: {
          url: "https://youtu.be/RFsTW17GTLQ",
          width: 384,
          height: 480,
        },
        comment: "",
      },
    ],
    tags: "Vidéo",
    groupe: [],
  },
  {
    id: "i3",
    type: "newLayout",
    titleStrong: "La place",
    titleWeak: "",
    description:
      "La place est le point central de l’opération d’aménagement des espaces publics de votre quartier. Pour la commune et la Métropole ,  elle est un un lieu de rencontre, de passage, de support aux activités existantes: piscines, crèche et aire de jeu, mais aussi un endroit où peut émerger de nouveaux usages.",
    thumbnail: "i3-thumb.png",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "imgTxt",
        description: (
          <>
            <p>
              La place est le point central de l’opération d’aménagement des
              espaces publics de votre quartier.
            </p>
            <p style={{ marginTop: "1rem" }}>
              Pour la commune et la Métropole ,  elle est un un lieu de
              rencontre, de passage, de support aux activités existantes:
              piscines, crèche et aire de jeu, mais aussi un endroit où peut
              émerger de nouveaux usages.
            </p>
            <p style={{ marginTop: "1rem" }}>
              La future place comportera plusieurs zones (voir carte). Au-delà
              de servir de support de lieu de rencontre, ce nouveau parvis est
              pensé comme un petit parc entrecoupé de cheminements piétons. Dans
              ce nouvel espace, une place importante est donnée au végétal et un
              grand soin est apporté à l’éclairage: garantissant votre sécurité,
              le plaisir des yeux quand la nuit tombe et la tranquillité des
              espèces animales et végétales
            </p>
          </>
        ),
        title: "La place",
        image: "i3-thumb.png",
        comment: "",
      },
    ],
    tags: "Illustration",
    groupe: [],
  },
  {
    id: "c1",
    type: "newLayout",
    titleStrong: "Un nom pour",
    titleWeak: "le nouveau passage",
    description:
      "Entre la rue Allende et la rue Gauguin requalifiée jusqu’au parc de la plaine des Sports, un passage est créé entre les immeubles pour permettre aux piétons de se déplacer en toute tranquillité au cœur du quartier. Pour renommer ce passage nous avons décidé de faire honneur aux femmes, malheureusement trop peu présentes dans l’espace public. Aussi, nous avons besoin de vous pour choisir qui d’une femme célèbre dans le sport, la culture, la politique ou la science aura l’honneur de représenter cette place pour les prochaines années.",
    thumbnail: "c1-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Un nom pour le nouveau passage",
        question:
          "Quelle figure féminine pourrait donner son nom à ce passage ?",
        imageUrl: "c1-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Personnalité Politique.",
            value: "rep1",
            image: "c1-q1-1.svg",
          },
          {
            label: "Personnalité sportive.",
            value: "rep2",
            image: "c1-q1-2.svg",
          },
          {
            label: "Personnalité culturelle.",
            value: "rep3",
            image: "c1-q1-3.svg",
          },
          {
            label: "Personnalité scientifique.",
            value: "rep4",
            image: "c1-q1-4.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "imgTxt",
        description: (
          <>
            <p>Pensez-vous à quelqu’un en particulier ?</p>
          </>
        ),
        title: "Un nom pour le nouveau passage",
        image: "c1-q2-1.svg",
        comment: "",
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c2",
    type: "newLayout",
    titleStrong: "Personnaliser",
    titleWeak: "l’atmosphère du passage “Allende”",
    description:
      "Entre la rue Allende et la rue Gauguin requalifiée jusqu’au parc de la plaine des Sports, un passage est créé entre les immeubles pour permettre aux piétons de se déplacer en toute tranquillité au cœur du quartier. Avec cette fiche, vous pouvez personnaliser l’atmosphère que prendra ce futur passage...Avec l’atmosphère, c’est le style, l’aspect en résumé le visage de ce passage qui est en jeu.",
    thumbnail: "c2-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser l’atmosphère du passage “Allende”",
        question:
          "Parmi les choix d’atmosphère proposés, lequel à votre préférence ?",
        imageUrl: "c2-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un passage qui favorise la nature sauvage.",
            value: "rep1",
            image: "c2-q1-1.svg",
          },
          {
            label: "Un passage plus urbain, plus minéral.",
            value: "rep2",
            image: "c2-q1-2.svg",
          },
          {
            label: "Un passage qui présente une nature ordonnée.",
            value: "rep3",
            image: "c2-q1-3.svg",
          },
          {
            label: "Un passage qui crée de l’événement !",
            value: "rep4",
            image: "c2-q1-4.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c3",
    type: "newLayout",
    titleStrong: "Personnaliser l’allée",
    titleWeak: "du nouveau passage “Allende”",
    description:
      "Entre la rues Allende et la rue Gauguin requalifiée jusqu’au parc de la plaine des Sports, un passage est créé entre les immeubles pour permettre aux piétons de se déplacer en toute tranquillité au cœur du quartier. Avec cette fiche, vous pouvez personnaliser les allées de ce passage...et le mobilier qui va avec!",
    thumbnail: "c3-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Personnaliser l’allée du nouveau passage “Allende”",
        question:
          "Deux options s’offrent à vous pour la personnalisation des allées du passage “Allende”: Une allée simple de 3m de large et largement bordées de végétaux, ou une allée de 3m de large aussi mais ici ponctuée de petits squares pour accueillir de nouveaux usages. Faites votre choix!",
        imageUrl: "c3-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Une allée piétonne simple largement bordée de végétaux.",
            value: "rep1",
            image: "c3-q1-1.svg",
          },
          {
            label:
              "Une allée piétonne qui peut accueillir ponctuellement de petits équipements et mobiliers",
            value: "rep2",
            image: "c3-q1-2.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "radio",
        title: "Personnaliser l’allée du nouveau passage “Allende”",
        question:
          "Si vous êtes ici, c’est que vous avez opté pour une allée piétonne qui peut accueillir des activités. Dans le projet, les activités sont placées sur des petits squares aux abords de l’allée principale. Quel type d’activité verrez-vous principalement dans ces petits squares ? En choisissant un type d’espace plutôt qu’un autre, c’est aussi le mobilier de l’allée que vous changez. A vous de choisir parmi les espaces proposés:",
        imageUrl: "c3-q2-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un espace de contemplation.",
            value: "rep1",
            image: "c3-q2-1.svg",
          },
          {
            label: "Un espaces de jeux d’échecs et de jeux de dames.",
            value: "rep2",
            image: "c3-q2-2.svg",
          },
          {
            label: "Un espace de partage.",
            value: "rep3",
            image: "c3-q2-3.svg",
          },
          {
            label: "Un espace libre pour pratiquer du sport en groupe.",
            value: "rep4",
            image: "c3-q2-4.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "c3-q2-5.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c4",
    type: "newLayout",
    titleStrong: "Un nouveau nom",
    titleWeak: "pour la place",
    description:
      "Pour renommer la place qui va faire peau neuve avec l’opération d’aménagement du quartier, nous avons décidé de faire honneur aux femmes, malheureusement trop peu présentes dans l’espace public. Aussi, nous avons besoin de vous pour choisir qui d’une femme célèbre dans le sport, la culture, la politique ou la science aura l’honneur de représenter cette place pour les prochaines années.",
    thumbnail: "c4-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Un nouveau nom pour la place",
        question:
          "Quelle figure féminine pourrait donner son nom à cette place ?",
        imageUrl: "c4-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Personnalité Politique.",
            value: "rep1",
            image: "c4-q1-1.svg",
          },
          {
            label: "Personnalité sportive.",
            value: "rep2",
            image: "c4-q1-2.svg",
          },
          {
            label: "Personnalité culturelle.",
            value: "rep3",
            image: "c4-q1-3.svg",
          },
          {
            label: "Personnalité scientifique.",
            value: "rep4",
            image: "c4-q1-4.svg",
          },
        ],
      },
      {
        contentId: "content-2",
        type: "imgTxt",
        description: (
          <>
            <p>Pensez-vous à quelqu’un en particulier ?</p>
          </>
        ),
        title: "Un nouveau nom pour la place",
        image: "c4-q2-1.svg",
        comment: "",
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c5",
    type: "newLayout",
    titleStrong: "Les nouveaux usage",
    titleWeak: "de la future place",
    description:
      "Dans le cadre du projet d’aménagement du quartier de la Piscine, les voiries seront requalifiées et une toute nouvelle place va émerger dans votre quartier. Plus grande, plus agréable et mieux intégrée au quartier, cette future place imaginée comme un parc pourra accueillir de nouveaux usages qui vont profiter à tous les habitants du quartier.",
    thumbnail: "c5-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "Les nouveaux usage de la future place",
        question:
          "La future place a pour vocation de desservir les équipements déjà présents, mais en grandissant de taille elle pourra aussi accueillir de nouveaux usages. Quels usages voyez-vous pour cette place ?",
        imageUrl: "c5-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un endroit où se ressourcer.",
            value: "rep1",
            image: "c5-q1-1.svg",
          },
          {
            label: "Un endroit où se rencontrer.",
            value: "rep2",
            image: "c5-q1-2.svg",
          },
          {
            label: "Un endroit où s’amuser à tout âge.",
            value: "rep3",
            image: "c5-q1-3.svg",
          },
          {
            label: "Une place qui préserve la biodiversité.",
            value: "rep4",
            image: "c5-q1-4.svg",
          },
          {
            label: "Une place à vocation culturelle.",
            value: "rep5",
            image: "c5-q1-4.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "c5-thumb.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c6",
    type: "newLayout",
    titleStrong: "L’atmosphère",
    titleWeak: "de la future place",
    description:
      "Dans le cadre du projet d’aménagement du quartier de la Piscine, les voiries seront requalifiées et par cette requalification une toute nouvelle place va émerger dans votre quartier. Plus grande, plus agréable et mieux intégrer au quartier cette future  place imaginée comme un parc sera parcourue de cheminements piétons et vélos qui vont donner un caractère et une atmosphère bien particuliers à cette place.",
    thumbnail: "c6-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "L’atmosphère de la future place",
        question:
          "Parmi les choix d’atmosphère proposés, lequel à votre préférence ?",
        imageUrl: "c6-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label: "Un passage qui favorise la nature sauvage.",
            value: "rep1",
            image: "c6-q1-1.svg",
          },
          {
            label: "Un passage plus urbain, plus minéral.",
            value: "rep2",
            image: "c6-q1-2.svg",
          },
          {
            label: "Un passage qui présente une nature ordonnée.",
            value: "rep3",
            image: "c6-q1-3.svg",
          },
          {
            label: "Un passage qui crée de l’événement.",
            value: "rep4",
            image: "c6-q1-4.svg",
          },
          {
            label: "J’ai une autre idée.",
            value: "rep100",
            image: "c6-q1-5.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
  {
    id: "c7",
    type: "newLayout",
    titleStrong: "L’éclairage",
    titleWeak: "place et du quartier en général",
    description:
      "La place comme les voiries doivent être éclairées quand la nuit tombe. Derrière cette question, il y a des enjeux de sécurité, d’aspect visuel, mais aussi de pollution nocturne.",
    thumbnail: "c7-thumb.svg",
    submitted: false,
    content: [
      {
        contentId: "content-1",
        type: "radio",
        title: "L’éclairage de la future place et du quartier en général",
        question:
          "Parmi ces choix de luminaires qui permettront d’éclairer les cheminements dans le quartier et sur la nouvelle place, lequel à votre préférence ?",
        imageUrl: "c7-q1-1.svg",
        value: "",
        comment: "",
        options: [
          {
            label:
              "Un éclairage à faisceau structuré sur les espaces plantés de la place (l’éclairage est plus diffus, mais le rendu est plus esthétique)+ un éclairage « classique » sur les autres voiries du projet.",
            value: "rep1",
            image: "c7-q1-1.svg",
          },
          {
            label:
              "Un éclairage suspendu par caténaire sur la place du et sur la chaussée attenante (L’éclairage est suspendu comme des lampions mais aussi plus ponctuel) + Un éclairage « classique » sur les autres voiries du projet.",
            value: "rep2",
            image: "c7-q1-2.svg",
          },
        ],
      },
    ],
    tags: "Personnalisable",
    groupe: [],
  },
];
