import React from "react";

export const CalendarData = [
  // {
  //   id: 0,
  //   emoji: "🚀",
  //   title: "Etape 1",
  //   date: "",
  //   duration: "",
  //   plan: "",
  //   substeps: [
  //     {
  //       id: 0,
  //       emoji: "",
  //       title: "Lancement de la concertation",
  //       date: "31 Janvier 2022",
  //       duration: "",
  //       description:
  //         "Début des démarches de concertation et d’information sur le projet de requalification du quartier de la piscine",
  //     },
  //   ],
  // },
  {
    id: 1,
    emoji: "🚶‍♀️",
    title: "Etape 2",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "",
        title: "Une balade urbaine et atelier participatif",
        date: "01 Février 2022 de 18h à 20h",
        duration: "",
        description:
          "Présentation du projet et des démarches de concertation. Le 1 février 2022 de 18h à 20h -Rendez vous à 18h à l’aire de jeux rue Neruda puis à l’école Saint juste pour l’atelier.",
      },
    ],
  },
  {
    id: 2,
    emoji: "🙋‍♂️",
    title: "Etape 3",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "",
        title: "Des stands du projet au centre commercial",
        date: "02 Mars 2022",
        duration: "",
        description:
          "Présentation du projet et accompagnement concertation numérique. Le 2 mars 2022 de 16h à 18h rendez vous au niveau du Carrefour Market, avenue Jean Jaures.",
      },
    ],
  },
  {
    id: 3,
    emoji: "👷‍♂️",
    title: "Etape 4",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "",
        title: "Atelier de co-construction",
        date: "09 Mars 2022 à la maison du projet (parking crèche Brin de Malice) de 16h à 18h",
        duration: "",
        description:
          "Présentation du projet et accompagnement concertation numérique. Lors de cette atelier, nous vous présenterons le projet et ses enjeux et vous accompagnons dans la participation de la concertation numérique.",
      },
    ],
  },
  {
    id: 4,
    emoji: "🏁",
    title: "Etape 5",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "",
        title: "Clôture de la plateforme",
        date: "16 Mars 2022",
        duration: "",
        description:
          "C’est la fin de la concertation numérique. Nous revenons très bientôt vers vous pour vous présenter les résultats de votre participation.",
      },
    ],
  },
  {
    id: 5,
    emoji: "📊",
    title: "Etape 6",
    date: "",
    duration: "",
    plan: "",
    substeps: [
      {
        id: 0,
        emoji: "",
        title: "Réunion de restitution",
        date: "04 Avril 2022 de 18h à 20h à l’école Saint-Just",
        duration: "",
        description:
          "C’est l’heure des résultats! Lors de cette réunion, nous allons vous présenter les résultats issus de cette compagne de concertation. L’heure et le lieu de cette réunion seront précisés très bientôt.",
      },
    ],
  },
];
