import { db } from "../config/firebase";

// Adds the locataire profile setup info
export const addLocInfo = (profileSetupData, locataireID) => {
  const {
    name,
    lastName,
    birthYear,
    sexe,
    flatSize,
    numCars,
    numMotors,
    numBikes,
    habitant,
    habitation,
  } = profileSetupData;
  const locInfo = {
    // fullName: `${name.value} ${lastName.value}`,
    birthYear: birthYear.value,
    sexe: sexe.value,
    // flatSize: flatSize.value,
    numCars: numCars.value,
    numMotors: numMotors.value,
    numBikes: numBikes.value,
    habitant: habitant.value,
    // habitation: habitation.value,
  };

  db.collection("locataires")
    .doc(`anon-${locataireID}`)
    .update(locInfo)
    .then((resp) => {
      console.log("Doc successfully updated");
    })
    .catch((err) => {
      console.log("Error while updating doc :", err);
    });
};
