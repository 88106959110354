import React from "react";
import classes from "./PDC.module.css";
import Auxiliary from "../../../hoc/Auxiliary";

const pdc = (props) => {
  return (
    <Auxiliary>
      <h2 className={classes.CGUTitle}>Politique de confidentialité</h2>
      <div className={classes.CGUWrapper}>
        <div className={classes.ArticleContent}>
          <p>
            Vous trouverez ci-dessous la Politique de confidentialité de la
            Plateforme quartier-inspire.corehab.fr, autrement dit l’explica tion
            de nos pratiques et de nos engagements vis-à-vis du traitement de
            vos données à caractère personnel, en conformité notamment avec le
            Règlement Général sur la Protection des Données (RGPD).
          </p>
          <p>
            Conscients de l’importance d’une information claire et transparente
            en la matière, nous y avons intégré différents tableaux pour vous
            aider à mieux comprendre et exercer vos droits.
          </p>
          <p>
            Cette politique a été mise à jour pour la dernière fois le
            29/03/2021.
          </p>
          <p>
            <strong>Bon à savoir</strong> : Les termes et expressions définis
            dans les CGU (termes et expressions portant une majuscule) ont la
            même définition dans la présente Politique. Reportez-vous à
            l’article 2 des CGU si vous n’êtes pas sûr(e) de ce que signifie un
            de ces termes ou expressions.
          </p>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            1. QUI EST LE RESPONSABLE DU TRAITEMENT DE VOS DONNEES ?
          </h3>
          <div className={classes.ArticleContent}>
            <p>
              La plateforme accessible à l’adresse quartier-inspire.corehab.fr
              est éditée par la société 1001RUES (l’Editeur), société à
              responsabilité limitée immatriculée au Registre du Commerce et des
              Sociétés de Paris sous le numéro 812 720 027, et dont le siège
              social est situé 90, rue du Dessous des Berges à PARIS 13E
              (75013).
            </p>

            <p>
              Le Projet objet de la concertation proposée sur la Plateforme est
              toutefois l’initiative et la responsabilité du Porteur du Projet,
              à savoir la société Métropole Rouen Normandie, activité
              administration publique générale sous le numéro de SIRET :
              20002341400101, et dont le siège social est situé 108 allée
              François Mitterrand BP 1180 76176 ROUEN CEDEX.
            </p>
            <p>
              L’Editeur et le Porteur du Projet ayant déterminé conjointement
              les finalités et les moyens des traitements de données à caractère
              personnel mis en œuvre dans le cadre de la Plateforme, elles sont
              toutes deux conjointement responsables de ces traitements au sens
              du RGPD.
            </p>
            <p>
              Par exception à ce qui précède, certains traitements de données
              sont effectués par l’Editeur sous sa seule responsabilité ; ces
              traitements sont clairement identifiés dans la section 2
              ci-dessous.
            </p>
            <p>
              <strong>Bon à savoir</strong> : L’Editeur et le Porteur du Projet
              se sont organisés pour faciliter l’exercice de vos droits prévus
              par le RGPD et la loi française à l’égard de vos données à
              caractère personnel. Pour ce faire, ils ont désigné l’Editeur
              (c’est-à-dire la société 1001RUES) comme votre interlocuteur
              privilégié pour toute demande d’exercice de l’un de ces droits
              (voir sous la section 5 ci-après).
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            2. QUELLES DONNEES SONT COLLECTEES VOUS CONCERNANT, ET POUR QUELLES
            RAISONS ?
          </h3>
          <h4 className={classes.ArticleSubtitle}>
            a. Connexion à la Plateforme (requête http)
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Lorsque vous vous connectez à la Plateforme, un certain nombre de
              données sont automatiquement collectées par l’hébergeur de cette
              Plateforme concernant votre terminal (ordinateur, smartphone,
              tablette) et votre navigateur. Cela inclut notamment votre adresse
              IP.
            </p>
            <p>
              Ces données sont collectées aux fins d’assurer la connexion entre
              votre terminal et les serveurs de la Plateforme, ainsi
              qu’ultérieurement pour détecter des bugs et des tentatives de
              cyberattaque.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            b. Cookies Cloudflare (cookies de sécurité)
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Comme expliqué dans la Politique cookies de la Plateforme, cette
              dernière utilise des cookies de la société Cloudflare Inc. aux
              fins de détecter et bloquer des connexions massives automatisées
              (« robots ») visant à compromettre la sécurité et la disponibilité
              des serveurs (attaques DDoS).
            </p>
            <p>
              Ces cookies de sécurité peuvent collecter des informations
              minimales telles que votre identifiant de connexion (adresse IP)
              et une indication de ce que vous êtes bien un internaute humain
              (par opposition à un « robot » envoyant des requêtes de connexion
              en masse aux serveurs).
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            c. Création du Compte Locataire
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              La création de votre Compte Participant nécessite que nous
              collections votre âge, votre sexe et le type de mobilité que vous
              utilisez. Ces données sont utilisées pour mieux comprendre vos
              Avis Participant et la manière dont ils concernent le Projet.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>d. Avis Participant</h4>
          <div className={classes.ArticleContent}>
            <p>
              Lorsque vous validez un Avis Participant sur l’Espace de
              concertation, comme expliqué dans les CGU, cet Avis Participant
              est reçu et enregistré par l’Editeur de la Plateforme. Il est
              rattaché à votre Compte Participant à des fins de traçabilité.
            </p>
            <p>
              Les données correspondantes (contenu de l’Avis Participant,
              identifiant du Compte Participant, date et heure de validation de
              l’Avis Participant) sont ainsi traitées pour recevoir votre Avis
              Participant, l’analyser dans le cadre de l’Opération de
              concertation et à des fins de traçabilité.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>e. Formulaire de contact</h4>
          <div className={classes.ArticleContent}>
            <p>
              La Plateforme contient un formulaire de contact vous permettant
              d’adresser aux équipes de l’Editeur vos questions et commentaires
              concernant la Plateforme.
            </p>
            <p>
              Lorsque vous envoyez un message via ce formulaire, nous recevons
              et collectons votre adresse électronique (telle qu’indiquée dans
              le formulaire), le contenu de votre message (tel qu’indiqué dans
              le formulaire) ainsi que la date et l’heure d’envoi de ce message.
            </p>
            <p>
              <strong>Important :</strong>L’Editeur est seul responsable du
              traitement des données liées à ce formulaire de contact, le
              Porteur du Projet n’intervenant pas dans ce traitement.
            </p>
          </div>
          <h4 className={classes.ArticleSubtitle}>
            f. Conservation de preuves par l’Editeur
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              L’Editeur pourra conserver tout ou partie des données liées aux
              Comptes Participant et aux Avis Participants après la fin de
              l’Opération de concertation, à titre de preuve afin de démontrer
              la bonne exécution de ses obligations à l’égard, notamment, du
              Porteur du Projet.
            </p>
            <p>
              <strong>Important : </strong>Cette conservation est effectuée sous
              la seule responsabilité de l’Editeur, le Porteur du Projet
              n’intervenant pas dans ce traitement.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            3. QUELLE EST LA JUSTIFICATION DU TRAITEMENT DE VOS DONNEES ?
          </h3>
          <div className={classes.ArticleContent}>
            <p>
              Les différents traitements de données décrits ci-avant sont
              justifiés :
            </p>
            <p>
              <strong>
                Soit par l’intérêt légitime des responsables conjoints du
                traitement,
              </strong>{" "}
              à savoir l’intérêt légitime du Porteur du Projet à consulter les
              Participants avant la mise en œuvre du Projet et l’intérêt
              légitime de l’Editeur à proposer au Porteur du Projet sa
              Plateforme de concertation numérique (article 6.1.f) du RGPD) ;
              tel est le cas :
            </p>
            <p>
              - Du traitement des données de navigation et des données
              collectées via les cookies ;
            </p>
            <p>
              - Du traitement des données liées aux Comptes Participant et aux
              Avis Participant aux fins d’établir le rapport final de
              l’Opération de concertation.
            </p>
            <p>
              <strong>
                Soit par l’exécution d’un contrat entre vous et un responsable
                du traitement,
              </strong>{" "}
              à savoir les CGU de la Plateforme (article 6.1.b) du RGPD) ; tel
              est le cas :
            </p>
            <p>
              - Du traitement des données nécessaires à la création et au
              maintien de votre Compte Participant.
            </p>
            <p>
              <strong>
                Soit par un intérêt légitime propre à l’Editeur de la Plateforme
              </strong>{" "}
              (article 6.1.f) du RGPD) ; tel est le cas :
            </p>
            <p>
              - De la conservation des données par l’Editeur à titre de preuve
              (intérêt légitime de l’Editeur à défendre ses droits le cas
              échéant en justice) ;
            </p>
            <p>
              - Des données reçues via le formulaire de contact (intérêt
              légitime de l’Editeur à assister les utilisateurs dans le cadre de
              leur utilisation de la Plateforme).
            </p>
            <p>
              Il est précisé que vous pouvez vous opposer aux traitements de vos
              données ayant pour fondement un intérêt légitime, mais non à ceux
              ayant pour fondement l’exécution d’un contrat (voir sous la
              section 5 ci-après).
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            4. QUI A ACCES A VOS DONNEES ? AVEC QUI SONT-ELLES PARTAGEES ?
          </h3>
          <div className={classes.ArticleContent}>
            <p>
              Vos données sont hébergées chez un prestataire professionnel
              sécurisé, sur des serveurs situés dans l’Union Européenne.
            </p>
            <p>
              Les équipes et salariés de l’Editeur sont susceptibles d’y accéder
              dans le cadre et les limites de leurs fonctions, c’est-à-dire
              principalement pour la réalisation du rapport final de l’Opération
              de concertation à destination du Porteur du Projet.
            </p>
            <p>
              Certaines de vos données peuvent également être consultées ou à
              tout le moins hébergées par les personnes et organisations
              suivantes :
            </p>
            <p>
              - Les prestataires techniques chargés du développement et de la
              maintenance de la Plateforme et des bases de données y associées ;
            </p>
            <p>
              - Les conseils juridiques des responsables du traitement, le cas
              échéant.
            </p>
            <p>Ces prestataires sont tous situés dans l’Union Européenne.</p>
            <p>
              Par ailleurs, en cas de cession ou de rachat de l’Editeur, de
              transfert de tout ou partie de ses actifs, ou de toute autre
              opération équivalente, nous serons susceptibles de transférer vos
              données à l’acheteur ou au cessionnaire concerné. Nous vous
              informerons, dans un tel cas, de l’identité de ce destinataire et
              des raisons du transfert.
            </p>
            <p>
              Enfin, exceptionnellement, nous pouvons également être tenus de
              partager tout ou partie des données qui précèdent avec les
              autorités et juridictions compétentes pour ordonner leur
              communication, et/ou avec les autorités, juridictions et parties
              (ainsi que leurs éventuels conseils) aux litiges et procédures que
              nous déclencherions ou auxquels nous serions contraints
              d’intervenir, pour la défense de nos droits et intérêts.
            </p>
          </div>
        </div>

        <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}>
            5. DE QUELS DROITS DISPOSEZ-VOUS POUR CONTROLER LE TRAITEMENT DE VOS
            PROPRES DONNEES ?
          </h3>
          <div className={classes.ArticleContent}>
            <p>
              Vous disposez, à l’égard du traitement de vos données à caractère
              personnel, d’un certain nombre de droits prévus par la
              réglementation.
            </p>
            <p>
              Vous en trouverez le détail ci-après. Un tableau récapitulatif
              vous est par ailleurs proposé en-dessous.
            </p>
            <p>
              Vous pouvez exercer ces droits simplement, en écrivant directement
              à l’Editeur à l’adresse électronique suivante :
              contact@1001rues.com Pensez à bien indiquer dans votre email la
              nature du droit que vous souhaitez exercer et les raisons qui
              justifient, le cas échéant, votre demande d’exercice de ce droit.
            </p>
          </div>
          <h4 className={classes.ArticleSubtitle}>a. Droit d’accès</h4>
          <div className={classes.ArticleContent}>
            <p>
              Vous avez le droit de nous demander une copie des données à
              caractère personnel dont nous disposons vous concernant, dans un
              format aisément compréhensible, ainsi qu’une copie de la présente
              politique sur support durable.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>b. Droit de rectification</h4>
          <div className={classes.ArticleContent}>
            <p>
              Vous avez le droit de nous demander de corriger, de compléter ou
              de mettre à jour les données dont nous disposons vous concernant,
              dans le cas où elles vous sembleraient inexactes, incomplètes ou
              obsolètes.
            </p>
            <p>
              Dans ce cas, nous vous remercions de bien vouloir nous communiquer
              spontanément, dans la mesure du possible, les nouvelles
              informations nécessaires pour procéder à la correction, à la
              complétion ou à la mise à jour demandée.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>c. Droit d’opposition</h4>
          <div className={classes.ArticleContent}>
            <p>
              S’agissant des traitements de données listés ci-avant qui ont pour
              justification un intérêt légitime d’un ou plusieurs responsables
              du traitement, vous disposez du droit de vous y opposer pour des
              raisons tenant à votre situation particulière.
            </p>
            <p>
              Autrement dit, vous pouvez demander aux responsables du traitement
              la cessation de l’un et/ou l’autre de ces traitements à votre
              égard, en exposant les raisons particulières qui justifient cette
              demande de votre point de vue.
            </p>
            <p>
              Il pourra toutefois arriver que les responsables du traitement
              refusent de donner suite à votre demande, si la poursuite de ce
              traitement est nécessaire pour des motifs impérieux de leur point
              de vue (par exemple : si les données concernées sont nécessaires
              pour la protection et la défense de leurs droits en justice).
            </p>
            <p>
              L’opposition (si elle est fondée sur des raisons valables et
              qu’aucun motif impérieux ne s’y oppose) entraînera la cessation du
              traitement pour l’avenir, mais pas nécessairement la destruction
              des données concernées : vous devez pour obtenir cette destruction
              exercer votre droit à l’effacement dans les conditions décrites
              ci-après, étant précisé que ce dernier connaît des limitations
              tenant par exemple, là encore, à la nécessité de conserver les
              données pour la protection et la défense des intérêts de l’un ou
              l’autre des responsables du traitement en justice.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>d. Droit à l’effacement</h4>
          <div className={classes.ArticleContent}>
            <p>
              Vous pouvez nous demander de procéder à la suppression de tout ou
              partie des données dont nous disposons vous concernant, dès lors
              que l’une au moins des conditions suivantes est remplie :
            </p>
            <p>
              - Vous vous êtes opposé(e) à la poursuite de ce traitement
              conformément au point c. ci-avant, et souhaitez de surcroît que
              les responsables du traitement procèdent à la destruction des
              données concernées.
            </p>
            <p>
              - Vous considérez que les responsables du traitement ont collecté
              et/ou traité les données concernées de façon contraire à la loi.
            </p>
            <p>
              - Vous considérez que a collecté et/ou traité les données
              concernées de façon contraire à la loi.
            </p>
            <p>
              - L’effacement des données concernées est imposé au titre d’une
              obligation légale.
            </p>
            <p>
              - Les données concernées sont relatives à une personne qui avait
              moins de quinze (15) ans lors de la collecte de ces données.
            </p>
            <p>
              Sachez toutefois que les responsables du traitement sont en droit
              de s’opposer à la suppression de certaines données, lorsque leur
              conservation est nécessaire pour des raisons particulièrement
              importantes, telles que la protection et la défense de leurs
              intérêts en justice.
            </p>
            <p>
              Sachez également que nous pourrons choisir, en lieu et place de la
              suppression des données, de procéder à leur anonymisation complète
              et irréversible. De cette manière, nous serons en droit de
              conserver ces données dans un format ne permettant plus de vous
              identifier (par exemple : à des fins statistiques).
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            e. Droit à la limitation du traitement
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              À défaut par exemple d’exercer votre droit à l’effacement, vous
              pouvez aussi demander aux responsables du traitement de « mettre
              de côté » certaines données vous concernant, c’est-à-dire de
              conserver ces données de façon séparée, sans plus les utiliser
              (sauf obligations légales).
            </p>
            <p>
              Vous pouvez formuler une telle demande lorsque l’une au moins des
              conditions suivantes est remplie :
            </p>
            <p>
              - Les données concernées vous paraissent inexactes, et vous
              préférez que les responsables du traitement cessent de les
              utiliser le temps de les vérifier et de les rectifier si
              nécessaire.
            </p>
            <p>
              - Vous avez exercé votre droit d’opposition conformément au point
              c. ci-avant, et vous préférez que les responsables du traitement
              cessent d’utiliser les données concernées le temps de vérifier le
              bienfondé de votre opposition.
            </p>
            <p>
              - Vous considérez que les responsables du traitement ont collecté
              et/ou traité les données concernées de façon contraire à la loi,
              mais préférez malgré tout que nous conservions les données plutôt
              que de les supprimer.
            </p>
            <p>
              - Les données concernées ne sont plus nécessaires pour aucune des
              finalités exposées ci-dessus, mais vous souhaitez que les
              responsables du traitement les conservent malgré tout pour les
              besoins de la défense de vos intérêts en justice.
            </p>
            <p>
              Dans ces hypothèses, nous mettrons les données « en quarantaine »
              pour la durée nécessaire, au moyen par exemple d’un marquage « Ne
              pas utiliser – Droit à la limitation ».
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            f. Droit à la portabilité des données
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Vous pouvez nous demander de vous adresser une copie des données
              liées à votre Compte Participant dans un format informatique
              usuel, permettant leur réutilisation par vous-même ou un autre
              prestataire de service.
            </p>
            <p>
              Ce « droit à la portabilité » se distingue du droit d’accès en ce
              que son but n’est pas d’obtenir une copie des données lisible par
              vous-même, mais bien une copie réutilisable et lisible par
              machine.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            g. Droit de définir des directives relatives au sort de vos données
            après votre mort
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Enfin, vous disposez du droit de nous indiquer la manière dont
              vous souhaitez que nous nous occupions de vos données dans
              l’hypothèse malheureuse de votre mort.
            </p>
            <p>
              Vous pouvez notamment nous demander de procéder à la destruction
              de l’ensemble de vos données (sous réserve des besoins impérieux
              de conservation que nous pourrions en avoir, par exemple à des
              fins de défense des droits des responsables du traitement en
              justice), ou encore de transmettre une copie de toutes ces données
              à une personne de votre choix.
            </p>
            <p>
              Vous pouvez également désigner toute personne de votre choix comme
              responsable de l’exécution de ces « dernières volontés » ; cette
              personne n’a pas nécessairement à être l’un de vos héritiers ni
              même l’exécuteur testamentaire chargé de votre succession.
            </p>
          </div>

          <h4 className={classes.ArticleSubtitle}>
            Vous considérez que nous n’avons pas répondu de façon satisfaisante
            à votre demande, ou que nous traitons vos données de façon illégale
            ?
          </h4>
          <div className={classes.ArticleContent}>
            <p>
              Nous vous invitons bien sûr tout d’abord à vous rapprocher de
              nous, pour que nous discutions ensemble du problème, et tâchions
              de le résoudre ensemble de la meilleure manière possible.
            </p>
            <p>
              Si vous le souhaitez, vous avez cependant le droit de saisir
              l’autorité compétente pour la protection des données en France, à
              savoir la Commission Nationale de l’Informatique et des Libertés
              (CNIL), via son site Internet cnil.fr ou par courrier postal à
              l’adresse suivante : CNIL – 3, place de Fontenoy – TSA 80715 –
              75334 PARIS CEDEX 07.
            </p>
            <p>
              Ce droit peut être exercé à tout moment et n’occasionne aucun
              frais pour vous, mis à part les frais d’expédition du courrier
              postal le cas échéant, et les frais éventuels d’assistance ou de
              représentation si vous choisissez de vous faire aider dans cette
              procédure par un tiers.
            </p>
          </div>
        </div>
      </div>
      {/* <div className={classes.Article}>
          <h3 className={classes.ArticleTitle}></h3>
          <h4 className={classes.ArticleSubtitle}></h4>
          <div className={classes.ArticleContent}>
            
          </div>
        </div> */}
    </Auxiliary>
  );
};

export default pdc;
