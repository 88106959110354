import React, { useContext } from "react";
// CONTEXTS
import { ProfileSetupContext } from "../../../contexts/ProfileSetupContext";
// MUI COMPONENTS
// import Radio from "../../muiComponents/Radio/Radio";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";

const QuestionOne = (props) => {
  const { profileSetupData, dispatch } = useContext(ProfileSetupContext);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "1rem",
      }}
    >
      <p style={{ marginBottom: ".5rem", fontSize: "1.1rem" }}>
        Quel est votre sexe ?
      </p>
      <RadioGroup
        aria-label={profileSetupData.sexe.elementConfig.name}
        name={profileSetupData.sexe.elementConfig.name}
        value={profileSetupData.sexe.value}
        onChange={(e) => dispatch({ type: "SET_SEXE", sexe: e.target.value })}
      >
        {Object.keys(profileSetupData.sexe.elementConfig.options).map(
          (option, indexOption) => (
            <FormControlLabel
              style={{
                marginTop: ".3rem",
              }}
              key={profileSetupData.sexe.elementConfig.options[option].value}
              value={profileSetupData.sexe.elementConfig.options[option].value}
              control={<Radio color="secondary" />}
              label={
                profileSetupData.sexe.elementConfig.options[option].displayValue
              }
            />
          )
        )}
      </RadioGroup>
    </div>
  );
};

export default QuestionOne;
