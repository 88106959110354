import React, { useEffect } from "react";
import { motion } from "framer-motion";
import styled from "styled-components";
import Title from "../elements/CardTitle";
import NextButton from "../elements/NextButton";
import Wrapper from "../elements/CardWrapper";
import CommentInput from "../elements/CommentInput";
import LinearProgress from "@material-ui/core/LinearProgress";
import { uploadAnswer } from "../../utils/TravauxUtils";

const ButtonWrapper = styled(motion.div)`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const ImageWrapper = styled(motion.div)`
  margin: 1rem auto;
  max-width: 20rem;
`;

const CommentWrapper = styled(motion.div)`
  background: #f9f9f9;
  border-radius: 20px;
  margin: 1rem 2rem;
  padding: 1rem;
`;

const ImgTxtType = ({
  dispatch,
  contentData,
  cardID,
  cardIndex,
  contentIndex,
  contentLength,
  currentUser,
  cardsLength,
}) => {
  const { title, description, image, contentId, comment } = contentData;

  const imagePreview = (
    <ImageWrapper>
      <img
        alt={title}
        src={require(`../../assets/travaux/${image}`)}
        style={{ width: "100%" }}
      />
    </ImageWrapper>
  );

  const handleSubmit = (cardID, contentId) => {
    let answers = {
      cardID: cardID,
      value: true,
      ["comment"]: comment,
    };
    uploadAnswer(currentUser.uid, cardID, contentId, answers, dispatch);
  };

  useEffect(() => {
    dispatch({
      type: "SET_OPENEDCARD",
      cardID: cardID,
    });
  }, []);

  return (
    <Wrapper
      variant="cardMain"
      layoutId="wrapper"
      initial={{ borderRadius: 40 }}
      animate={{ borderRadius: 0 }}
      transition={{ duration: 0.5 }}
    >
      <LinearProgress
        variant="determinate"
        style={{ width: "85%", margin: "1rem auto" }}
        color="secondary"
        value={(contentIndex / contentLength) * 100}
      />
      <Title
        layoutId="title"
        transition={{ duration: 0.5 }}
        color="#fff"
        style={{ paddingLeft: "2rem" }}
      >
        {title}
      </Title>

      {imagePreview}

      <CommentWrapper>{description}</CommentWrapper>

      <CommentWrapper>
        <CommentInput
          comment={comment}
          dispatch={dispatch}
          cardID={cardID}
          contentIndex={contentIndex}
          cardIndex={cardIndex}
          label="Votre idée :"
        />
      </CommentWrapper>

      <ButtonWrapper
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        <NextButton
          handleSubmit={() => handleSubmit(cardID, contentId)}
          href={
            parseInt(contentIndex) < contentLength - 1
              ? `/travaux/${cardID}/${parseInt(contentIndex) + 1}`
              : "/travaux"
          }
        >
          Valider
        </NextButton>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default ImgTxtType;
