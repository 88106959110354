import React, { useState, useEffect, useContext } from "react";
import classes from "./Home.module.css";
// Firebase
import app from "../../config/firebase";
// Routing
import { withRouter } from "react-router";
// Components
import HomeToolbar from "../../components/Travaux/Navigation/HomeToolbar/HomeToolbar";
import HomeFooter from "../../components/Travaux/Footer/HomeFooter";
import ViewSelector from "../../components/HomePage/ViewSelector/ViewSelector";
import Calendar from "../../components/HomePage/Calendar/Calendar";
import Projet from "../../components/HomePage/Projet/Projet";
// context
import { AuthContext } from "../../contexts/AuthContext";
// animation
import { motion } from "framer-motion";

const Home = ({ history }) => {
  const [view, setView] = useState(1);
  const [claims, setClaims] = useState();

  const [width, setWidth] = useState(window.innerWidth);
  const { currentUser } = useContext(AuthContext);
  useEffect(() => {
    // Get custom claims
    currentUser &&
      app
        .auth()
        .currentUser.getIdTokenResult()
        .then((idTokenResult) => setClaims(idTokenResult.claims))
        .catch((err) => console.log(err));

    /* Inside of a "useEffect" hook add an event listener that updates
       the "width" state variable when the window size changes */
    const handleWindowResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleWindowResize);
    // Return a function from the effect that removes the event listener
    return () => window.removeEventListener("resize", handleWindowResize);
  }, []);

  return (
    <motion.div
      className={classes.Home}
      initial={{ opacity: 0, x: -100 }}
      animate={{ opacity: 1, x: 0 }}
      exit={{ opacity: 0, x: -100 }}
      transition={{ duration: 0.2, ease: [0.6, 0.01, -0.05, 0.9] }}
    >
      <HomeToolbar />
      <div className={classes.SubNavbar}>
        <ViewSelector width={width} view={view} setView={setView} />
        <div className={classes.ActionButton}>
          <div className={classes.ActionButtonSvg}>
            <img
              style={{ width: "100%" }}
              alt="hand"
              src={require(`../../assets/homePage/main.svg`)}
            />
          </div>
          <p onClick={() => history.push("/travaux")}>
            Commencer la concertation
          </p>
        </div>
      </div>
      <div
        className={classes.main}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      >
        {view === 1 ? (
          <Projet
            width={width}
            claims={claims}
            clicked={() => history.push("/travaux")}
          />
        ) : (
          <Calendar width={width} />
        )}
      </div>
      <div className={classes.Footer}>
        <HomeFooter />
      </div>
    </motion.div>
  );
};

export default withRouter(Home);
